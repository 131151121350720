<template>
  <div class="page" style="text-align: left">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 60px;padding-top:20px;">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="height: 40px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>D r a c o N a t u r a l P r o d u c t s, I n c. </b>
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>Safety Data Sheet</u></b>
            </p>
          </div>
        </div>
        <div style="clear: both"></div>
        <table
          style="width: 100%; text-align: center;font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 1: PRODUCT AND COMPANY IDENTIFICATION</b>
            </td>
          </tr>
        </table>
        <!-- Product info -->
        <div>
          <div style="width: 45%; float: left">
            <table style="width: 99%;font-size: 12px">
              <tr>
                <td style="width: 25%"><b>Product Name</b></td>
                <td>
                  <span t-field="o.name" />{{
                    printModel.formModel.productName
                  }}
                </td>
              </tr>
              <tr v-show="printModel.form.versionNumber">
                <td style="width: 23%"><b>Version Number</b></td>
                <td>
                  <span t-field="o.name" />{{ printModel.form.versionNumber }}
                </td>
              </tr>

              <tr v-show="printModel.formModel.itemCode">
                <td style="width: 25%"><b>Item Code</b></td>
                <td>
                  <span t-field="o.default_code" />{{
                    printModel.formModel.itemCode
                  }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.botanicalName">
                <td style="width: 25%" valign="top"><b>Botanical Name</b></td>
                <td>
                  <span t-field="o.default_code" />{{
                    printModel.formModel.botanicalName
                  }}
                </td>
              </tr>
              <tr v-show="printModel.formModel.inciName">
                <td style="width: 25%" valign="top"><b>INCI Name</b></td>
                <td>
                  <span t-field="o.default_code" />{{
                    printModel.formModel.inciName.replace(/\(and\)/g," (and) ")
                  }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%"><b>MATERIAL USES</b></td>
                <td>
                  Botanical oil used as cosmetic/personal care and food/dietary ingredient
                </td>
              </tr>
              <tr>
                <td><b>EMERGENCY CONTACT</b></td>
                <td>Call 911 in case of emergency</td>
              </tr>
            </table>
          </div>
          
          <div style="width: 50%; float: left">
            <table style="width: 100%; font-size: 12px">
              <tr>
                <td style="width: 50%">
                  <b>SUPPLIER/MANUFACTURER</b>
                </td>
                <td>Draco Natural Products, Inc.</td>
              </tr>
              <tr>
                <td></td>
                <td>539 Parrott Street</td>
              </tr>
              <tr>
                <td></td>
                <td>San Jose, CA 95112 USA</td>
              </tr>
              <tr>
                <td></td>
                <td>TEl. 408-287-7871</td>
              </tr>
              <tr>
                <td></td>
                <td>FAX. 408-287-8838</td>
              </tr>
              <tr>
                <td></td>
                <td><a href="www.draconatural.com">www.draconatural.com</a></td>
              </tr>
            </table>
          </div>
        </div>

        <div style="height: 20px"></div>
        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 2: HAZARDS IDENTIFICATION</b>
            </td>
          </tr>
        </table>
        <table style="width: 80%;font-size: 12px;">
          <tr>
            <td><b>Classification of the substance or mixture</b></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b
                >GHS Classification in accordance with 29 CFR 1910 (OSHA HCS)</b
              >
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>
              <b>GHS Label elements, including precautionary statements</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td><b>Pictogram</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>Signal word</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>Hazard statement(s)</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>Precautionary statement(s)</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>
              <b
                >Hazards not otherwise classified (HNOC) or not covered by
                GHS</b
              >
            </td>
            <td>N/A</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 3: COMPOSITION/INFORMATION ON INGREDIENTS</b>
            </td>
          </tr>
        </table>
        <template v-if="printModel.formModel.rowData.length > 0">
          <table style="width: 98%;font-size: 12px;" cellspacing="0" class="tab">
            <thead>
              <tr>
                <th>SubstanceName</th>
                <th>CAS Number</th>
                <th>EinecsNumber</th>
                <th>"R"PHRAS</th>
                <th>"S"PHRASE</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(item, i) in printModel.formModel.rowData">
                <td>{{ item.substanceName[0] }}</td>
                <td>{{ item.casNumber }}</td>
                <td>{{ item.einecsNumber }}</td>
                <td>{{ item.rphras }}</td>
                <td>{{ item.sphrase }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <div style="margin-top: 40px"></div>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 4: FIRST AID MEASURES</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>General information</b></td>
            <td>No special measures required</td>
          </tr>
          <tr>
            <td><b>Skin contact</b></td>
            <td>
              Irrigate thoroughly with soap and cold water. Seek medical
              attention if symptoms occur
            </td>
          </tr>
          <tr>
            <td><b>Inhalation</b></td>
            <td>
              Remove person to fresh air, loosen clothing and seek expert
              medical help. Give artificial respiration if not breathing.
            </td>
          </tr>
          <tr>
            <td><b>Eye contact</b></td>
            <td>
              Check for and remove any contact lens. In case of contact with
              eyes, irrigate thoroughly with cold water. Seek medical attention
              of symptoms occur.
            </td>
          </tr>
          <tr>
            <td><b>Ingestion</b></td>
            <td>
              Do not induce vomiting. Never give anything by mouth to an
              unconscious person. Get medical attention if symptoms appear.
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 5: FIRE-FIGHTING MEASURES</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td style="width: 300px"><b>Flash Point</b></td>
            <td>Greater than 300 F</td>
          </tr>
          <tr>
            <td><b>Extinguishing media</b></td>
            <td>
              <b style="margin-right: 10px">Suitable</b> Use an extinguishing
              agent suitable for the surrounding fire
            </td>
          </tr>
          <tr>
            <td></td>
            <td><b style="margin-right: 10px">Not Suitable</b>None known</td>
          </tr>
          <tr>
            <td><b>Special exposure hazards</b></td>
            <td>No specific hazard</td>
          </tr>
          <tr>
            <td><b>Special exposure hazards</b></td>
            <td>No specific hazard</td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>Special protective equipment for fire-fighters</b></td>
            <td></td>
          </tr>
          <tr>
            <td>
              Fire-fighters should wear a self-contained breathing apparatus
              (SCBA) pressure-demand, MSHA/NIOSH (approved or equivalent), and
              full protective gear.
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 6: ACCIDENTAL RELEASE MEASURES</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td style="width: 20%"><b>General information</b></td>
            <td>
              Use suitable protective clothing and equipment (see Section 8)
            </td>
          </tr>
          <tr>
            <td><b>Spills/Leaks</b></td>
            <td>
              Absorb onto an inert, absorbent substrate and sweep up. Wash area with soap and water to prevent slip/
              fall hazard.  Discard spilled product and dispose in accordance with Local Authority Regulations
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 7: HANDLING AND STORAGE</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>Handling</b></td>
            <td>
              Use appropriate personal protective equipment (PPE) in well
              ventilated areas and methods that minimize dust generation. Avoid
              breathing dust, mist, or vapor. Avoid contact with skin and eyes.
            </td>
          </tr>
          <tr>
            <td><b>Storage</b></td>
            <td>
              Store in a cool, dry, well-ventilated area in a tightly closed
              container.
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 8: EXPOSURE CONTROLS/PERSONAL PROTECTION</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td>Consult local authorities for acceptable exposure limits</td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td style="width: 30%"><b>Engineering measure</b></td>
            <td>Local exhaust recommended</td>
            <td></td>
          </tr>
          <tr>
            <td><b>Hygiene measures</b></td>
            <td>
              Follow good manufacturing practice standards. Wash hands,
              forearms, face, and any area exposed to this product before and
              after being in contact with this product..
            </td>
            <td></td>
          </tr>
        </table>
        <table style="font-size: 10px;">
          <tr>
            <td><b>Personal protection</b></td>
            <td>Eyes</td>
            <td>Safety goggles</td>
          </tr>
          <tr>
            <td></td>
            <td>Skin</td>
            <td>Lab coats, boots, and appropriate laboratory appeal</td>
          </tr>
          <tr>
            <td></td>
            <td>Hands</td>
            <td>Disposable rubber or vinyl gloves</td>
          </tr>
          <tr>
            <td></td>
            <td>Respiratory</td>
            <td>
              A respirator is not needed under normal and intended conditions of
              product use
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 9: PHYSICAL AND CHEMICAL PROPERTIES</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td style="width: 150px"><b>Appearance</b></td>
            <td>{{ printModel.formModel.appearance }}</td>
            <td style="width: 150px"><b>Boiling point</b></td>
            <td>N/A</td>
            <td style="width: 260px"><b>Molecular formula</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>Color</b></td>
            <td>{{ printModel.formModel.color }}</td>
            <td><b>Melting point</b></td>
            <td>N/A</td>
            <td><b>Molecular weight</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>Odor</b></td>
            <td>{{ printModel.form.odor }}</td>
            <td><b>Viscosity</b></td>
            <td>N/A</td>
            <td><b>Decomposition temperature</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>Solubility</b></td>
            <td>{{printModel.form.solubility}}</td>
            <td><b>Specific gravity</b></td>
            <td>N/A</td>
            <td><b>Auto ignition temperature</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>pH</b></td>
            <td>{{ printModel.formModel.ph }}</td>
            <td><b>Vapor pressure</b></td>
            <td>N/A</td>
            <td><b>Explosive properties</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>Evaporation rate</b></td>
            <td>N/A</td>
            <td><b>Vapor density</b></td>
            <td>N/A</td>
            <td><b>Explosive limits</b></td>
            <td>N/A</td>
          </tr>
          <tr>
            <td><b>Flash point</b></td>
            <td>Greater than 300 F</td>
            <td><b>Specific density</b></td>
            <td>N/A</td>
            <td><b>Oxidizing properties</b></td>
            <td>N/A</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 10: STABILITY AND REACTIVITY</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>Chemical stability</b></td>
            <td>
              Stable under normal temperature, moisture level, and pressure
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>Conditions to avoid</b></td>
            <td>Incompatible materials; airborn powder</td>
            <td><b>Incompatibilities with other materials</b></td>
            <td>None known</td>
          </tr>
          <tr>
            <td><b>Hazardous polymerization</b></td>
            <td>Has not been reported</td>
            <td><b>Hazardous decomposition products</b></td>
            <td>None known</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 11: TOXICOLOGICAL INFORMATION</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>Routes of entry</b></td>
            <td>Ingestion, skin contact, inhalation</td>
          </tr>
          <tr>
            <td><b>Acute effects</b></td>
            <td>
              <table style="width:100%;">
                <tr>
                  <td style="width:20%;">LD50</td>
                  <td style="width:20%;">N/A</td>
                  <td style="width:20%;">LC50</td>
                  <td style="width:20%;">N/A</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td><b>Eyes</b></td>
            <td>
              No known significant effect or critical hazard; however, it may
              cause eye irritation due to physical contact with dust.
            </td>
          </tr>
          <tr>
            <td><b>Skin</b></td>
            <td>
              No known significant effect or critical hazard; however, it may
              cause skin irritation due to physical contact with dust.
            </td>
          </tr>
          <tr>
            <td><b>Inhalation</b></td>
            <td>
              No known significant effect or critical hazard; however, it may
              cause irritation of nose and mouth due to dust.
            </td>
          </tr>
          <tr>
            <td><b>Ingestion</b></td>
            <td>
              No known significant effect or critical hazard; however, it may
              cause gastric irritation in some individuals if ingested.
            </td>
          </tr>
          <tr>
            <td><b>Potential chronic health effects</b></td>
            <td>Not known</td>
          </tr>
        </table>
        <table style="font-size: 12px;">
          <tr>
            <td><b>Carcinogenic effects</b></td>
            <td></td>
            <td><b>IRAC</b></td>
            <td>No</td>
            <td><b>OSHA</b></td>
            <td>No</td>
            <td><b>ACGIH</b></td>
            <td>No</td>
            <td><b>Mutagenic effects</b></td>
            <td>Not available</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td><b>NTP</b></td>
            <td>No</td>
            <td><b>EU</b></td>
            <td>No</td>
            <td><b>Other</b></td>
            <td>No</td>
            <td><b>Teratogeniceffects</b></td>
            <td>Notavailable</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 12: ECOLOGICAL INFORMATION</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>Environmental precautions</b></td>
            <td>No known to have significant effect or critical hazard</td>
          </tr>
          <tr>
            <td><b>Products of biodegradation</b></td>
            <td>
              The product itself and its products of degradation have no known
              significant toxic effect ecologically.
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 13: DISPOSAL CONSIDERATIONS</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>Waste disposal</b></td>
            <td>
              Must be disposed of in accordance with federal, state and local
              environmental control regulations
            </td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 14: TRANSPORT INFORMATION</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>DOT classification</b></td>
            <td>Not a DOT controlled material (United States).</td>
          </tr>
          <tr>
            <td><b>Customs classification</b></td>
            <td>{{ printModel.form.customsClassification }}</td>
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 15: REGULATORY INFORMATION</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <b
              >Dangerous Substances Directive 67/548/EEC and Dangerous
              Preparations Directive 1999/45/EC:</b
            >
          </tr>
          <tr>
            This product is not classified as dangerous according to 67/548/EEC
            or 99/45/EC as amended.
          </tr>
          <tr>
            <b
              >OSHA Permissible Exposure Limit (PEL), ACGIH Threshold Limit
              Value (TLV), or other explosure limits:</b
            >
          </tr>
          <tr>
            This has not been established since this product is not a chemical.
            This is not a hazardous substance or of a synthetic chemical origin.
          </tr>
        </table>

        <table
          style="width: 100%; text-align: center;font-size: 12px;"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>SECTION 16: OTHER INFORMATION</b>
            </td>
          </tr>
        </table>
        <table style="width: 100%;font-size: 12px;">
          <tr>
            <td><b>Date created</b></td>
            <td>{{ formatDate(printModel.formModel.createTime) }}</td>
            <td><b>Last updated</b></td>
            <td>{{ formatDate(printModel.formModel.updateTime==null?new Date():printModel.formModel.updateTime) }}</td>
            <td><b>Updated by</b></td>
            <td>{{ printModel.formModel.editName  }}</td>
          </tr>
        </table>
        <p style="font-size: 10px; margin: 0px; padding: 0px">
          *This product is not regulated as a RCRA hazard waste by the federal
          EPA; however, it may be regulated in certain states.
        </p>
        <p style="font-size: 10px; margin: 0px; padding: 0px">
          *This is a food grade product for industrial and professional use
          only.
        </p>
        <p style="font-size: 10px; margin: 0px; padding: 0px">
          *THE INFORMATION ACCUMULATED HEREIN IS BELIEVED TO BE ACCURATE, BUT IS
          NOT WARRANTED TO BE, WHETHER ORIGINATING WITH THE COMPANY OR NOT.
          RECIPIENTS ARE ADVISED TO CONFIRM IN ADVANCE OF NEED THAT THE
          INFORMATION IS CURRENT, APPLICABLE, AND SUITABLE TO THEIR
          CIRCUMSTANCES.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import util from "../../common/base";
export default {
  name: "CSFMU",
  props: ["printModel"],
  data() {
    return {
      username: "", 
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.ingredientsArr.length,
        this.printModel.inclNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.commonOtherNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
  },
  methods: {
    getTime() {
      return util.methods.getLocalTime(-5);
    },
    formatDate(time) {
      return util.methods.formatDate({ date: time });
    },
  },
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
.tab {
  margin-top: 20px;
}
</style>