<template>
  <div style="height: 100vh; overflow: scroll">
    <div id="printDiv" v-show="printShow">
      <SDSPowder :printModel="this.$data" style="height-max: 1000px; width: 100vw" v-if="formModel.sdsType == 'Powder'"></SDSPowder>
      <SDSOil :printModel="this.$data" style="height-max: 1000px; width: 100vw" v-if="formModel.sdsType == 'Oil'"></SDSOil>
      <SDSLiquid :printModel="this.$data" style="height-max: 1000px; width: 100vw" v-if="formModel.sdsType == 'Liquid'"></SDSLiquid>
    </div>
    <div>
      <div class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading...">
        <div class="new-item-head">
          TECH DOC. EDITOR Import {{ titleType }}
          <span v-if="showVerified">
            <i
              :class="verify_show.icon"
              :style="{ color: verify_show.color }"
            ></i>
          </span>
          <span
            style="position: absolute; left: 10px; cursor: pointer"
            @click="back"
          >
            <i class="el-icon-arrow-left"></i>
          </span>
          <span
            style="
              position: absolute;
              right: 10px;
              cursor: pointer;
              font-weight: 400;
              font-size: 14px;
            "
          >
            <el-dropdown placement v-if="userInfo.username">
              <div>
                <i class="el-icon-user" style="margin-right: 10px"></i>
                {{ userInfo.username }}
                <i class="el-icon-arrow-down"></i>
                <!-- <span class="user-name">语言切换</span> -->
                <el-dropdown-menu class="user-dropdown" slot="dropdown">
                  <el-dropdown-item @click.native="logoutHandle"
                    >Logout</el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </span>
        </div>
      </div>
      <div v-if="isShowAlonePrint" style="width:21cm;margin-left:28%;">
        <div id="alonePrintDiv">
          <SDSPowder :printModel="this.$data" style="height-max: 1000px; " v-if="formModel.sdsType == 'Powder'"></SDSPowder>
          <SDSOil :printModel="this.$data" style="height-max: 1000px; " v-if="formModel.sdsType == 'Oil'"></SDSOil>
          <SDSLiquid :printModel="this.$data" style="height-max: 1000px; " v-if="formModel.sdsType == 'Liquid'"></SDSLiquid>
        </div>
        <div style="margin-top: 50px;">
        <el-button type="info" icon="el-icon-edit" @click="showUpdate()" v-if="isShowUpdate">Update</el-button>
        <el-button type="success" icon="el-icon-printer" @click="aloneprintPage()">Print</el-button>
        <el-button type="info" @click="closePage()">Exit</el-button>
        </div>
      </div>
      <div v-else
        style="
          background: white;
          margin-top: 40px;
          width: 90%;
          margin-left: 2.5%;
          padding: 40px;
        "
      >
    <div style="position: absolute;text-align: left;margin: 50px 0 0 120px;color: red;z-index: 9;">
      <div style="height: 30px;line-height: 30px;">Rev：{{form.revNumber}}</div>
      <div style="height: 30px;line-height: 30px;">Date：{{form.libraryCreateTime}}</div>
    </div>
        <div
          style="font-size: 20px; float: left; height: 20px; margin-left: 20px"
        >
          Base Info
        </div>
        <div style="margin-top: 40px"></div>
        <center>
          <el-form
            label-position="right"
            label-width="310px"
            style="width: 900px; align: center"
            ref="formModel"
            :model="formModel"
          >
            <el-form-item label="Item Code">
              <el-autocomplete
                clearable
                ref="itemCode"
                v-model="formModel.itemCode"
                :fetch-suggestions="codeSearch"
                placeholder="Please Input"
                popper-class="select-option"
                :popper-append-to-body="false"
                style="width: 100%"
                @select="itemCodeSearch"
                @blur="itemCodeSearch"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item label="SDS Type" prop="region">
              <el-select
                ref="sdsType"
                v-model="formModel.sdsType"
                placeholder="select"
                clearable
                style="width: 100%"
                @change="selectSDSType"
              >
                <el-option
                  v-for="(item, i) in sdsTypeList"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="ProductName">
              <el-input
                v-model="formModel.productName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Version Number">
              <el-input
                v-model="form.versionNumber"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Custom Product Name ">
              <el-input
                v-model="form.customProductName "
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="BotanicalName">
              <el-input
                v-model="formModel.botanicalName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="INCI Name">
              <el-input
                v-model="formModel.inciName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>

            <el-form-item label="Appearance">
              <el-input
                v-model="formModel.appearance"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="pH">
              <el-input
                v-model="formModel.ph"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Color">
              <el-input
                v-model="formModel.color"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Solubility">
              <el-input
                v-model="form.solubility"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="Customs Classification">
              <el-input
                v-model="form.customsClassification"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="" v-show="formModel.sdsType == 'Liquid'">
              <el-checkbox v-model="formModel.liquidProduct"
                >This product meets the requirements for glycerin as described in
                the United States Pharmacopoeia (USP) 27 (March 2004)</el-checkbox
              >
            </el-form-item>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>RowData</span>
                <!-- <el-button
                  style="float: right"
                  type="primary"
                  icon="el-icon-circle-plus-outline"
                  @click="addRowHandle"
                  >Add Row</el-button
                > -->
              </div>
              <div
                class="text item"
                :key="i"
                v-for="(item, i) in formModel.rowData"
              >
                <el-form-item label="SubstanceName">
                  <!-- <el-input v-model="formModel.botanicalName" placeholder="Please Input"></el-input> -->
                  <div
                    style="display: inline-block; width: 615px; text-align: left"
                  >
                    <div
                      style="
                        display: flex;
                        justify: flex-start;
                        flex-wrap: wrap;
                        align-items: center;
                      "
                    >
                      <el-tag
                        :key="ti"
                        v-for="(tag, ti) in item.substanceName"
                        closable
                        :disable-transitions="false"
                        @click="tagHandle(item.substanceName, tag, ti)"
                        @close="handleClose(i, tag)"
                        >{{ tag }}</el-tag
                      >
                      <el-input
                        class="input-new-tag"
                        v-if="inciNameInputVisible"
                        v-model="inciNameInputValue"
                        ref="saveTagInput"
                        size="small"
                        @keyup.enter.native="
                          handleInputConfirm(
                            'inciNameInputValue',
                            'inciNameInputVisible',
                            i
                          )
                        "
                        @blur="
                          handleInputConfirm(
                            'inciNameInputValue',
                            'inciNameInputVisible',
                            i
                          )
                        "
                      ></el-input>
                      <el-button
                        v-else
                        class="button-new-tag"
                        size="small"
                        @click="showInput('inciNameInputVisible', i)"
                        >+ New SubstanceName</el-button
                      >
                    <el-button class="button-new-tag" size="small" icon="el-icon-circle-plus-outline" 
                    @click="addRowHandle">Add Row</el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="CAS Number">
                  <el-input
                    v-model="item.casNumber"
                    placeholder="Please Input"
                  ></el-input>
                </el-form-item>
                <el-form-item label="EinecsNumber">
                  <el-input
                    v-model="item.einecsNumber"
                    placeholder="Please Input"
                  ></el-input>
                </el-form-item>
                <el-form-item label='"R" PHRASES'>
                  <el-input v-model="item.rphras"></el-input>
                </el-form-item>
                <el-form-item label='"S" PHRASES'>
                  <el-input
                    v-model="item.sphrase"
                    placeholder="Please Input"
                  ></el-input>
                </el-form-item>

                <el-button
                  v-if="i + 1 > 1"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="deleteRowHandle(i)"
                ></el-button>
                <el-divider></el-divider>
              </div>
            </el-card>
          </el-form>
            <NoteTableData :baseInfo="this.$data" style="margin-top:20px;"></NoteTableData>
            <div style="margin-top: 20px">
              <el-button v-if="userInfo.operateStatus && formModel.verifiedList==0" type="warning" @click="handleNew('ContinueEdit',1)">Add to Verify List</el-button>
              <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('ContinueEdit')">Save</el-button>
              <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Save&New')">Save & New</el-button>
              <el-button
                :icon="isVerifing ? 'el-icon-loading' : ''"
                v-if="showVerified && pemission(userInfo.username) && userInfo.operateStatus"
                type="warning"
                @click="vertify"
                >Vertify</el-button
              >
              <el-button @click="back">Back Home</el-button>
              <el-button
                type="success"
                icon="el-icon-printer"
                @click="printPage()"
                >Print</el-button>
            </div>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import printJS from "print-js";
import SDSPowder from "./PrintProductTypeModule/SDS-Powder.vue";
import SDSOil from "./PrintProductTypeModule/SDS-Oil";
import SDSLiquid from "./PrintProductTypeModule/SDS-Liquid";
import NoteTableData from "./NoteTableData";

export default {
  name: "new-item",
  mixins: [base],
  components: { SDSPowder, SDSOil, SDSLiquid,NoteTableData },
  data() {
    return {
      addRevNumber:true,
      loading: false,
      printShow: false,
      isVerifing: false,
      showVerified: false,
      titleType: "Add",
      checkProductType: false,
      sdsTypeList: [
        { label: "Powder", value: "Powder" },
        { label: "Liquid", value: "Liquid" },
        { label: "Oil", value: "Oil" },
      ],
      inciNameInputVisible: "",
      inciNameInputValue: "",
      newItemType: "New",
      itemCodeSearchLock: "",
      revDateTime:base.methods.getLocalNowTime(),
      formModel: {
        itemCode: "",
        sdsType: "",
        productName: "",
        botanicalName: "",
        inciName: "",
        appearance: "",
        ph: "",
        color: "",
        liquidProduct: false,
        rowData: [
          {
            substanceName: [],
            casNumber: "",
            einecsNumber: "",
            rphras: "",
            sphrase: "",
          },
        ],
        editName: "",
        createTime: base.methods.getLocalTime(-7),
        updateTime: base.methods.getLocalTime(-7),
        jsonValue:"",
        verifiedList:0,
      },
      form:{
        customProductName:"",
        libraryRevNumber:0,
        libraryCreateTime:'',
        revNumber:0,
        revDate:[],
        noteTableData:"",
        versionNumber:"",
        solubility:"Water soluble/dispersible",
        customsClassification:"HTS#1302.19.9140",
        ordor: "",
        taste: "",
      },
      printObj: {
        id: "printDiv", // 这里是要打印元素的ID
        popTitle: "", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
      },
      substanceNameInfo: {
        substanceName: [],
        casNumber: "",
        einecsNumber: "",
        rphras: "",
        sphrase: "",
      },
      requestsubstanceName: {
        substanceName: "",
      },
      isShowAlonePrint:false,
      isShowUpdate:false,
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          reason:"",
          approvedBy: "",
          isComplete:false,
        },
      ],
    };
  },
  computed: {
    verify_show: {
      get() {
        switch (this.verified) {
          case "true":
            return { icon: "el-icon-success", color: "#67c23a" };
          case "false":
            return { icon: "el-icon-error", color: "#f56c6c" };
          default:
            return { icon: "el-icon-question", color: "#a6a9ad" };
        }
      },
      set() {
        switch (this.verified) {
          case "true":
            return { icon: "el-icon-success", color: "#67c23a" };
          case "false":
            return { icon: "el-icon-error", color: "#f56c6c" };
          default:
            return { icon: "el-icon-question", color: "#a6a9ad" };
        }
      },
    },
  },
  watch: {},
  methods: {
    showUpdate(){
      this.isShowAlonePrint = false
    },
    printPage() {
      if(this.formModel.sdsType==""){
        this.$message({
          message: 'Please Select SDS Type',
          type: 'warning'
        });
        return;
      }
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      var printFileName = this.formModel.productName;
      document.title = "(SDS)" + printFileName+"Rev."+this.form.revNumber;
      //console.log(document.title);
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);

      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    closePage(){
      if (navigator.userAgent.indexOf('MSIE') > 0) { // close IE
        if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
          window.opener = null;
          window.close();
        } else {
          window.open('', '_top');
          window.top.close();
        }
      } else { // close chrome;It is effective when it is only one.
        window.opener = null;
        window.open('', '_self');
        window.close();
      }
    },
    aloneprintPage() {
      const style = "@page {margin:0} @media print{}";
      var printFileName = this.formModel.productName;
      document.title = "(SDS)" + printFileName;
      setTimeout(() => {
        printJS({
          printable: "alonePrintDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);
    },
    back() {
      this.$router.go(-1);
    },
    codeSearch(queryString, cb) {
      //this.getDetailByItemCodeSearch(queryString);
      // var restaurants = this.$store.state.codeList;
      var codeList = JSON.parse(localStorage.getItem("itemCodeListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    itemCodeSearch(val) {
      if (typeof val.value != "undefined") {
        this.getDetailByItemCodeSearch(val.value);
      }
    },
    getDetailByItemCodeSearch(searchValue) {
      if (this.itemCodeSearchLock == searchValue) {
        this.itemCodeSearchLock = searchValue;
        return;
      }
      if (typeof searchValue == "undefined" || searchValue.length == 0) {
        return;
      }

      this.$api.handleSpec
        .getDetailByItemCode(searchValue)
        .then((res) => {
          if (res != null) {
            console.log("res");
            console.log(res);
            if (res.analysisRangePh != null && res.analysisRangePh != "") {
              this.formModel.ph = res.analysisRangePh;
            }
            //#region
            if (
              res.productType == "CSFMU-B" ||
              res.productType == "CSFMU-S" ||
              res.productType == "REXT" ||
              res.productType == "F&V" ||
              res.productType == "FMU-D" ||
              res.productType == "HEXT" ||
              res.productType == "OEXT" ||
              res.productType == "PCG" ||
              res.productType == "PCG-CSFMU-S" ||
              res.productType == "SDNP" ||
              res.productType == "PCG-CSFMU-B"
            ) {
              this.formModel.sdsType = "Powder";
            } else if (
              res.productType == "Liquid-S" ||
              res.productType == "Liquid-B" ||
              res.productType == "SDNP-L" ||
              res.productType == "Liquid EXT-B" ||
              res.productType == "Liquid EXT-S"
            ) {
              this.formModel.sdsType = "Liquid";
            } else if (
              res.productType == "SHXTO" ||
              res.productType == "EHXTO"
            ) {
              this.formModel.sdsType = "Oil";
            }
            let jsonValue = JSON.parse(res.jsonValue)
            //#endregion
            this.formModel.productName = res.productName;
            this.formModel.botanicalName = res.botanicalName.replace(/_/g, ",");
            this.formModel.appearance = res.appearance;
            console.log(res.analysisRangePh);
            let carrierSolvent = "";
            if (this.formModel.sdsType == "Liquid") {
              carrierSolvent = res.carrierSolvent;
              console.log("carrierSolvent==" + res.carrierSolvent);
              if (!this.formModel.appearance) {
                this.formModel.appearance = "Liquid";
              }
              this.formModel.ph = jsonValue.analysisRangePh;
              // if (!this.formModel.ph) {
              //   this.formModel.ph = "3.5-7";
              // }
            } else {
              this.formModel.ph = "Not Available";
            }
            console.log('jsonValue')
            console.log(jsonValue)
            this.form.versionNumber = jsonValue.versionNumber
            if (this.formModel.sdsType == "Powder") {
              this.formModel.color = jsonValue.specificationColor;
              this.form.odor = jsonValue.specificationOrdor;
              this.form.taste = jsonValue.specificationTaste;
            }
            else{
              this.formModel.color = jsonValue.color;
              this.form.odor = jsonValue.odor;
              this.form.taste = jsonValue.taste;
            }
            this.formModel.inciName =
              res.inciName == null ? "" : res.inciName.replace(/_/g, "(and)");
            if (typeof res.inciName != "undefined" && res.inciName.length > 0) {
              this.formModel.rowData = [];
              if (res.inciName.indexOf("(and)") > -1) {
                console.log("res.inciName");
                console.log(res.inciName.split("(and)"));
                for (let item of res.inciName.split("(and)")) {
                  this.formModel.rowData.push({
                    substanceName: [item.trim()],
                    casNumber: "",
                    einecsNumber: "",
                    rphras: "",
                    sphrase: "",
                  });
                }
                console.log('this.formModel.inciName============this.formModel.rowData')
                console.log(this.formModel.rowData)
                for (let item of res.inciName.split("(and)")) {
                  this.getSdsMatchUrl(item.trim());
                }
              } else {
                for (let item of res.inciName.split("_")) {
                  this.formModel.rowData.push({
                    substanceName: [item.trim()],
                    casNumber: "",
                    einecsNumber: "",
                    rphras: "",
                    sphrase: "",
                  });
                }
                for (let item of res.inciName.split("_")) {
                  this.getSdsMatchUrl(item.trim());
                }
              }
            }
            else if (typeof res.botanicalName != "undefined" && res.botanicalName.length > 0) {
              this.formModel.rowData = [];
              for (let item of res.botanicalName.split(",")) {
                this.formModel.rowData.push({
                  substanceName: [item.trim()],
                  casNumber: "",
                  einecsNumber: "",
                  rphras: "",
                  sphrase: "",
                });
              }
              for (let item of res.botanicalName.split(",")) {
                this.getSdsMatchUrl(item.trim());
              }
            }
            if(carrierSolvent){
              this.formModel.rowData.push({
                substanceName: [carrierSolvent],
                casNumber: "",
                einecsNumber: "",
                rphras: "",
                sphrase: "",
              });
              this.getSdsMatchUrl(carrierSolvent);
            }
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleSpec.getSdsDetail(params).then((data) => {
        this.loading =false;
        this.formModel = data;

        if(this.formModel.jsonValue){
          let jsonValue = JSON.parse(this.formModel.jsonValue)
          if(typeof(jsonValue.customsClassification)=="undefined"){
            jsonValue.customsClassification = "HTS#1302.19.9140";
          }
          this.form = jsonValue;
          
          if(!this.form.solubility){
            this.form.solubility = "Water soluble/dispersible"
          }
    
          if(this.form.noteTableData){
            this.noteTableData = JSON.parse(this.form.noteTableData);
          }
    
        }

        if (this.formModel.inciName == null) {
          this.formModel.inciName = "";
        }
        console.log("this.formModel");
        console.log(this.formModel);
        let rowData = JSON.parse(data.rowData);
        this.formModel.rowData = rowData;

        this.formModel.rowData.forEach((element) => {
          let array = element.substanceName;
          element.substanceName = [];
          array.forEach((subItem) => {
            if (subItem.trim() != "") {
              element.substanceName.push(subItem);
            }
          });
        });
        this.revDateTime = data.updateTime
        //如果是print page,则判断是否verifly,true显示，false提示
        if(params.aloneprint && !data.verified){
          this.$router.push('/no-pdf')
        }
      });
    },
    async handleNew(type,verifyType=0) {
      this.loading = true;
      this.form.noteTableData = JSON.stringify(this.noteTableData) 
      let revNumber = 0
      this.noteTableData.forEach(item=>{
        if(item.isComplete){
          revNumber ++
        }
      })
      this.form.revNumber = revNumber
      if (this.formModel.id != null) {
        this.form.revDate.push(this.revDateTime)
        if(verifyType == 1){
          this.formModel.verifiedList = 1
        }
        else if(verifyType == 2){
          this.formModel.verifiedList = 0
        }
      }
      this.formModel.jsonValue = JSON.stringify(this.form)
      var params = JSON.parse(JSON.stringify(this.formModel));
      this.formModel.rowData.forEach((element) => {
        element.substanceName.join("_");
      });
      params.rowData = JSON.stringify(this.formModel.rowData);

      if (params.id != null) {
        this.$api.handleSpec.editSdsItem(params).then((data) => {
          this.loading = false;
          console.log("editSdsItem")
          console.log(data)
          this.formModel = data;
          this.$message({
            type: "success",
            message: "SuccessFul",
          });
          let rowData = JSON.parse(data.rowData);
          this.formModel.rowData = rowData;
          this.formModel.rowData.forEach((element) => {
            let array = element.substanceName;
            element.substanceName = [];
            array.forEach((subItem) => {
              if (subItem.trim() != "") {
                element.substanceName.push(subItem);
              }
            });
          });
          this.revDateTime = data.updateTime
          this.titleType = "Edit";
          this.reloadPage(type);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      } else {
        this.$api.handleSpec.addSdsItem(params).then(() => {
          this.loading = false;
          var that = this
          this.$message({
            type: "success",
            message: "SuccessFul",
            duration: 1000,
            onClose: function () {
              that.$router.push({ path: '/home' })
            }
          });
          this.titleType = "Add";
          this.revDateTime = base.methods.getLocalNowTime()
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      }
    },
    reloadPage(type) {
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "new-item-sds",
          query: { id: undefined },
        });
        this.$router.go(0);
      }
    },
    handleClose(arrIndex, tag) {
      this.formModel.rowData[arrIndex].substanceName.splice(
        this.formModel.rowData[arrIndex].substanceName.indexOf(tag),
        1
      );
    },
    addRowHandle() {
      this.formModel.rowData.push({
        substanceName: [],
        casNumber: "",
        einecsNumber: "",
        rphras: "",
        sphrase: "",
      });
    },
    deleteRowHandle(index) {
      console.info(index);
      this.formModel.rowData.splice(index, 1);
    },
    vertify() {
      let arrtr = this.noteTableData[this.noteTableData.length-1]
      if(arrtr.revisionDate =='' || arrtr.requestedBy =='' || arrtr.itemsRevised =='' || arrtr.editedBy =='' || arrtr.reason ==''){
        this.$message({ type: "info", message: "Revision note Cannot be empty" });
        return
      }
      this.$confirm("Vertify context no question?", "提示", {
        confirmButtonText: "Verify Ok",
        cancelButtonText: "Verify Cancel",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          // this.$set(this.params,'verified','true');
          this.verifyChange(true);
        })
        .catch((action) => {
          // this.$set(this.params, "verified", "false");
          if (action == "cancel") {
            // this.verifyChange(false);
          }
        });
    },
    verifyChange(flag) {
      if (this.isVerifing) return;
      this.isVerifing = true;
      let params = {
        id: this.$route.query.id,
        pageIndex: 1,
        pageSorts: [
          {
            column: "",
            asc: false,
          },
        ],
        isFlag: flag,
        pageSize: 100,
        itemCode: "",
        productName: "",
      };
      this.$api
        .verfiedSdsItem(params)
        .then((data) => {
          console.log(data);
          this.$set(this, "verified", flag.toString());
          this.$message({ type: "success", message: "Verified SuccessFul" });
          this.isVerifing = false;
          this.noteTableData.forEach((item,i)=>{
            if(i == this.noteTableData.length-1){
              item.approvedBy = this.formModel.editName
              item.isComplete = true
            }
          })
          this.addToLibrary()
        })
        .catch(() => {
          // console.log(err);
          // this.$message({ type: "error", message: 'Verify Fail' });
          // this.$message({type:'error',message:err.data});
          // this.$set(this.params,'verified','false');
          this.isVerifing = false;
        });
    },
    addToLibrary(){
      this.$confirm(
        "Are you sure add to Library. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        let param = { ...this.$route.query };
        let params ={ids:[],type:'sds'}
        let ids=[]
        ids.push(param.id)
        params.ids = ids
        
        console.log('addToLibrary======params')
        console.log(params)
        this.$api
          .batchAddLibrary(params)
          .then(() => {
            this.$message({ type: "success", message: "Add SuccessFully" });
            this.noteTableData.push({
              revisionDate:"",
              requestedBy:"",
              itemsRevised:"",
              editedBy:"",
              approvedBy:"",
              reason:"",
              isComplete:false,
            });
            this.form.noteTableData = JSON.stringify(this.noteTableData) 
            let revNumber = 0
            this.noteTableData.forEach(item=>{
              if(item.isComplete){
                revNumber ++
              }
            })
            this.form.revNumber = revNumber
            this.form.libraryRevNumber = this.form.revNumber
            this.form.libraryCreateTime = base.methods.formatTime(base.methods.getLocalNowTime(),"dd/MM/yyyy hh:mm")
            this.formModel.jsonValue = JSON.stringify(this.form)
            this.formModel.verifiedList = 0
            var params = JSON.parse(JSON.stringify(this.formModel));
            this.formModel.rowData.forEach((element) => {
              element.substanceName.join("_");
            });
            params.rowData = JSON.stringify(this.formModel.rowData);
            this.$api.handleSpec
            .editSdsItem(params)
            .then(() => {
              this.loading = false;
              this.$message({
                type: "success",
                message: "SuccessFul",
              });
              this.revDateTime = base.methods.getLocalNowTime()
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    showInput(inputVisible, index) {
      this[inputVisible] = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput[index].$refs.input.focus();
      });
    },
    tagHandle(array, tagCotext, index) {
      //tag标签点击事件
      this.$prompt("修改值-->" + tagCotext, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    },
    handleInputConfirm(value, visible, index) {
      let inputValue = this[value];
      if (inputValue) {
        this.getSdsMatchUrl(this.inciNameInputValue);
        this.formModel.rowData[index].substanceName.push(
          this.inciNameInputValue
        );
      }

      this[visible] = false;
      this[value] = "";
    },
    selectSDSType() {
      console.log(this.formModel.sdsType);
      if (this.formModel.sdsType == "Liquid") {
        if (!this.formModel.appearance) {
          this.formModel.appearance = "Liquid";
        }
        if (!this.formModel.ph) {
          this.formModel.ph = "3.5-7";
        }
      }
    },
    async getSdsMatchUrl(value) {
      console.log("getSdsMatchUrl==value");
      console.log(value);
      if(!value){
      console.log('直接返回');
        return false
      }
      this.$api.handleSpec
        .sdsMatchUrl({ substanceName: value })
        .then((data) => {
          if (data != null) {
            console.log("this.substanceNameInfo = data");
            console.log(data);
            this.formModel.rowData.forEach((element) => {
              if (element.substanceName[0] == value) {
                element.casNumber = data.casNumber;
                element.einecsNumber = data.einecsNumber;
                element.rphras = data.rphras;
                element.sphrase = data.sphrase;
              }
            });
          }
        });
    },
    fatherMethod(revNumber){
      console.log('revNumber')
      console.log(revNumber)
    },
    AddToVerifyList(){},
  },
  created() {
    let specUserInfo = JSON.parse(localStorage.getItem('specUserInfo'))
    if(specUserInfo){
      this.formModel.editName = specUserInfo.username;
    }
    let params = { ...this.$route.query };
    if (params.id) {
      if(params.aloneprint){
        this.isShowAlonePrint = params.aloneprint == "1" ? true : false
      }
      if(params.showUpdate){
        this.isShowUpdate = params.showUpdate == "1" ? true : false
      }
      this.getDetailById(params);
      this.titleType = "Edit";
      this.newItemType = "Edit";
      this.showVerified = true;
    } else {
      this.titleType = "Add";
      this.newItemType = "Add";
    }

    //test
    var test = "test[0T1]testtest[01T13]t[0113]test";
    var regex2 = /\[(.+?)\]/g;
    var aa = test.match(regex2);
    aa.forEach((val, index) => {
      if (val.indexOf("T") != -1) {
        console.info(val, index);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin-left: 10px;
  margin-bottom: 10px;
}
.button-new-tag {
  margin-left: 10px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  margin-bottom: 10px;
  vertical-align: bottom;
}

::v-deep .select-option {
  min-width: 300px;
}
.app-new-item {
  .el-autocomplete ::v-deep .el-input__inner {
    width: 600px;
  }
  // width: 100%;
  // overflow: scroll;
  overflow-x: auto !important;
  overflow-y: auto;
  padding-top: 80px;
  .el-input,
  .el-select {
    width: 600px;
  }
  .area-input {
    height: 90px !important;
    line-height: 90px !important;
  }
  .new-item-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .new-item-content {
    margin: 40px 40px 140px;
    padding: 0 34px;
    background-color: #fff;
    > div {
      padding: 40px;
      border-bottom: 1px solid #eee;
    }
    > div:nth-last-child(1) {
      border: none;
    }
    > div > div:nth-of-type(1) {
      text-align-last: left;
      color: #000000;
      font-size: 20px;
    }
    .base-info > div:nth-of-type(2) > div,
    .general-specification > div:nth-of-type(2) > div {
      height: 60px;
      line-height: 60px;
    }
    .base-info > div:nth-of-type(2) > div > span,
    .general-specification > div:nth-of-type(2) > div > span {
      display: inline-block;
      padding-right: 10px;
      width: 300px;
      text-align: right;
    }
    .analysis > div:nth-of-type(2),
    .microbiological-tests > div:nth-of-type(2) {
      display: flex;
    }
    .analysis > div:nth-of-type(2) > div,
    .microbiological-tests > div:nth-of-type(2) > div {
      margin-top: 20px;
      display: inline-block;
      width: 50%;
      border: 1px solid #e9e9e9;
      text-align-last: left;
    }
    .analysis > div:nth-of-type(2) > div:nth-of-type(1),
    .microbiological-tests > div:nth-of-type(2) > div:nth-of-type(1) {
      margin-right: 120px;
    }
    .specification > div:nth-of-type(1),
    .test-method > div:nth-of-type(1) {
      height: 44px;
      line-height: 44px;
      padding-left: 30px;
      background-color: #f9fbfc;
      border-bottom: 1px solid #e9e9e9;
    }
    .specification > div,
    .test-method > div {
      display: flex;
      height: 60px;
      line-height: 60px;
    }
    .specification span,
    .test-method span {
      width: 25%;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      padding-right: 10px;
    }
    .specification .el-input,
    .test-method .el-input,
    .specification .el-select,
    .test-method .el-select {
      width: 40%;
      text-align: left;
    }
    .other-notes > div:nth-of-type(2),
    .other-notes > div:nth-of-type(3),
    .other-notes > div:nth-of-type(4) {
      // height: 60px;
      // line-height: 60px;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .other-notes > p {
      font-weight: 600;
      margin-top: 10px;
    }
    .other-notes > div:nth-of-type(2) span:nth-of-type(1),
    .other-notes > div:nth-of-type(3) span:nth-of-type(1),
    .other-notes > div:nth-of-type(4) span:nth-of-type(1) {
      display: inline-block;
      width: 200px;
      text-align: right;
      padding-right: 10px;
    }
    .other-notes > div:nth-of-type(2) span:nth-of-type(2),
    .other-notes > div:nth-of-type(3) span:nth-of-type(2) {
      display: inline-block;
      width: 500px;
      text-align: left;
      font-weight: 600;
    }
    .other-notes > div:nth-of-type(3) .el-input {
      display: inline-block;
      width: 500px;
      text-align: left;
      font-weight: 600;
    }
  }
  .velidate-item {
    ::v-deep .el-input__inner {
      border: 1px solid rgb(211, 71, 71);
    }
    ::placeholder {
      color: rgb(211, 71, 71);
    }
  }
}
</style>